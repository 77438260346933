import P1 from '@/images/compassion/1.jpg';
import P2 from '@/images/compassion/2.jpg';
import P3 from '@/images/compassion/3.jpg';
import P4 from '@/images/compassion/4.jpg';
import P5 from '@/images/compassion/5.jpg';
import P6 from '@/images/compassion/6.jpg';
import P7 from '@/images/compassion/7.jpg';
import P8 from '@/images/compassion/8.jpg';
import P9 from '@/images/compassion/9.jpg';
import P10 from '@/images/compassion/10.jpg';
import P11 from '@/images/compassion/11.jpg';
import P12 from '@/images/compassion/12.jpg';
import P13 from '@/images/compassion/13.jpg';
import P14 from '@/images/compassion/14.jpg';
import P15 from '@/images/compassion/15.jpg';
import P16 from '@/images/compassion/16.jpg';
import P17 from '@/images/compassion/17.jpg';
import P18 from '@/images/compassion/18.jpg';
import P19 from '@/images/compassion/19.jpg';
import P20 from '@/images/compassion/20.jpg';
import P21 from '@/images/compassion/21.jpg';
import P22 from '@/images/compassion/22.jpg';
import P23 from '@/images/compassion/23.jpg';
import P24 from '@/images/compassion/24.jpg';
import P25 from '@/images/compassion/25.jpg';
import P26 from '@/images/compassion/26.jpg';
import P27 from '@/images/compassion/27.jpg';
import P28 from '@/images/compassion/28.jpg';
import P29 from '@/images/compassion/29.jpg';
import P30 from '@/images/compassion/30.jpg';
// import P31 from '@/images/compassion/31.jpg';
// import P32 from '@/images/compassion/32.jpg';
// import P33 from '@/images/compassion/33.jpg';
// import P34 from '@/images/compassion/34.jpg';
// // import P5 from '@/images/compassion/5.jpg';
// import P36 from '@/images/compassion/36.jpg';
// import P37 from '@/images/compassion/37.jpg';
// import P38 from '@/images/compassion/38.jpg';
// import P39 from '@/images/compassion/39.jpg';
// import P40 from '@/images/compassion/40.jpg';
// import P41 from '@/images/compassion/41.jpg';
// import P42 from '@/images/compassion/42.jpg';
// import P43 from '@/images/compassion/43.jpg';
// // import P44 from '@/images/compassion/44.jpg';
// import P45 from '@/images/compassion/45.jpg';
// import P46 from '@/images/compassion/46.jpg';
// import P47 from '@/images/compassion/47.jpg';
// import P48 from '@/images/compassion/48.jpg';
// import P49 from '@/images/compassion/49.jpg';
// import P50 from '@/images/compassion/50.jpg';
// import P51 from '@/images/compassion/51.jpg';
// import P52 from '@/images/compassion/52.jpg';
// import P53 from '@/images/compassion/53.jpg';
// import P54 from '@/images/compassion/54.jpg';
// import P55 from '@/images/compassion/55.jpg';
// import P56 from '@/images/compassion/56.jpg';
// import P57 from '@/images/compassion/57.jpg';
// import P58 from '@/images/compassion/58.jpg';
// import P59 from '@/images/compassion/59.jpg';
// import P60 from '@/images/compassion/60.jpg';
// import P61 from '@/images/compassion/61.jpg';
// import P62 from '@/images/compassion/62.jpg';
// import P63 from '@/images/compassion/63.jpg';
// import P64 from '@/images/compassion/64.jpg';
// import P65 from '@/images/compassion/65.jpg';
// import P66 from '@/images/compassion/66.jpg';
// import P67 from '@/images/compassion/67.jpg';
// import P68 from '@/images/compassion/68.jpg';
// import P69 from '@/images/compassion/69.jpg';
// import P70 from '@/images/compassion/70.jpg';
// import P71 from '@/images/compassion/71.jpg';
// import P72 from '@/images/compassion/72.jpg';
// import P73 from '@/images/compassion/73.jpg';
// import P74 from '@/images/compassion/74.jpg';
// import P75 from '@/images/compassion/75.jpg';
// import P76 from '@/images/compassion/76.jpg';
// import P77 from '@/images/compassion/77.jpg';
// import P78 from '@/images/compassion/78.jpg';
// import P79 from '@/images/compassion/79.jpg';
// import P80 from '@/images/compassion/80.jpg';
// import P81 from '@/images/compassion/81.jpg';
// import P82 from '@/images/compassion/82.jpg';
// import P83 from '@/images/compassion/83.jpg';

export const photos = [
  {
    original: P1,
    thumbnail: P1,
  },
  {
    original: P2,
    thumbnail: P2,
  },
  {
    original: P3,
    thumbnail: P3,
  },
  {
    original: P4,
    thumbnail: P4,
  },
  {
    original: P5,
    thumbnail: P5,
  },
  {
    original: P6,
    thumbnail: P6,
  },
  {
    original: P7,
    thumbnail: P7,
  },
  {
    original: P8,
    thumbnail: P8,
  },
  {
    original: P9,
    thumbnail: P9,
  },
  {
    original: P10,
    thumbnail: P10,
  },
  {
    original: P11,
    thumbnail: P11,
  },
  {
    original: P12,
    thumbnail: P12,
  },
  {
    original: P13,
    thumbnail: P13,
  },
  {
    original: P14,
    thumbnail: P14,
  },
  {
    original: P15,
    thumbnail: P15,
  },
  {
    original: P16,
    thumbnail: P16,
  },
  {
    original: P17,
    thumbnail: P17,
  },
  {
    original: P18,
    thumbnail: P18,
  },
  {
    original: P19,
    thumbnail: P19,
  },
  {
    original: P20,
    thumbnail: P20,
  },
  {
    original: P21,
    thumbnail: P21,
  },
  {
    original: P22,
    thumbnail: P22,
  },
  {
    original: P23,
    thumbnail: P23,
  },
  {
    original: P24,
    thumbnail: P24,
  },
  {
    original: P25,
    thumbnail: P25,
  },
  {
    original: P26,
    thumbnail: P26,
  },
  {
    original: P27,
    thumbnail: P27,
  },
  {
    original: P28,
    thumbnail: P28,
  },
  {
    original: P29,
    thumbnail: P29,
  },
  {
    original: P30,
    thumbnail: P30,
  },
  // {
  //   original: P31,
  //   thumbnail: P31,
  // },
  // {
  //   original: P32,
  //   thumbnail: P32,
  // },
  // {
  //   original: P33,
  //   thumbnail: P33,
  // },
  // {
  //   original: P34,
  //   thumbnail: P34,
  // },
  // // {
  // //   original: P35,
  // //   thumbnail: P35,
  // // },
  // {
  //   original: P36,
  //   thumbnail: P36,
  // },
  // {
  //   original: P37,
  //   thumbnail: P37,
  // },
  // {
  //   original: P38,
  //   thumbnail: P38,
  // },
  // {
  //   original: P39,
  //   thumbnail: P39,
  // },
  // {
  //   original: P40,
  //   thumbnail: P40,
  // },
  // {
  //   original: P41,
  //   thumbnail: P41,
  // },
  // {
  //   original: P42,
  //   thumbnail: P42,
  // },
  // {
  //   original: P43,
  //   thumbnail: P43,
  // },
  // // {
  // //   original: P44,
  // //   thumbnail: P44,
  // // },
  // {
  //   original: P45,
  //   thumbnail: P45,
  // },
  // {
  //   original: P46,
  //   thumbnail: P46,
  // },
  // {
  //   original: P47,
  //   thumbnail: P47,
  // },
  // {
  //   original: P48,
  //   thumbnail: P48,
  // },
  // {
  //   original: P49,
  //   thumbnail: P49,
  // },
  // {
  //   original: P50,
  //   thumbnail: P50,
  // },
  // {
  //   original: P51,
  //   thumbnail: P51,
  // },
  // {
  //   original: P52,
  //   thumbnail: P52,
  // },
  // {
  //   original: P53,
  //   thumbnail: P53,
  // },
  // {
  //   original: P54,
  //   thumbnail: P54,
  // },
  // {
  //   original: P55,
  //   thumbnail: P55,
  // },
  // {
  //   original: P56,
  //   thumbnail: P56,
  // },
  // {
  //   original: P57,
  //   thumbnail: P57,
  // },
  // {
  //   original: P58,
  //   thumbnail: P58,
  // },
  // {
  //   original: P59,
  //   thumbnail: P59,
  // },
  // {
  //   original: P60,
  //   thumbnail: P60,
  // },
  // {
  //   original: P61,
  //   thumbnail: P61,
  // },
  // {
  //   original: P62,
  //   thumbnail: P62,
  // },
  // {
  //   original: P63,
  //   thumbnail: P63,
  // },
  // {
  //   original: P64,
  //   thumbnail: P64,
  // },
  // {
  //   original: P65,
  //   thumbnail: P65,
  // },
  // {
  //   original: P66,
  //   thumbnail: P66,
  // },
  // {
  //   original: P67,
  //   thumbnail: P67,
  // },
  // {
  //   original: P68,
  //   thumbnail: P68,
  // },
  // {
  //   original: P69,
  //   thumbnail: P69,
  // },
  // {
  //   original: P70,
  //   thumbnail: P70,
  // },
  // {
  //   original: P71,
  //   thumbnail: P71,
  // },
  // {
  //   original: P72,
  //   thumbnail: P72,
  // },
  // {
  //   original: P73,
  //   thumbnail: P73,
  // },
  // {
  //   original: P74,
  //   thumbnail: P74,
  // },
  // {
  //   original: P75,
  //   thumbnail: P75,
  // },
  // {
  //   original: P76,
  //   thumbnail: P76,
  // },
  // {
  //   original: P77,
  //   thumbnail: P77,
  // },
  // {
  //   original: P78,
  //   thumbnail: P78,
  // },
  // {
  //   original: P79,
  //   thumbnail: P79,
  // },
  // {
  //   original: P80,
  //   thumbnail: P80,
  // },
  // {
  //   original: P81,
  //   thumbnail: P81,
  // },
  // {
  //   original: P82,
  //   thumbnail: P82,
  // },
  // {
  //   original: P83,
  //   thumbnail: P83,
  // },
];
