import React from 'react';
import { Heading, Box, Center, useDisclosure, Button } from '@chakra-ui/react';

import QRSection from '@/components/Common/QRCard/V2';
import useInvitation from '@hooks/useInvitation';

import WithAnimation from '@components/Common/WithAnimation';
import AddToCalendarButton from '@components/Common/AddToCalendar';
import CountDown from '@components/Common/CountDown';
import WeddingDetail from './WeddingDetail';
import BaliGuestInformation from './BaliGuestInformation';

import {
  // WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  // WEDDING_RESEPSI_FULLDATE,
  GOOGLE_MAPS_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_TIME,
} from '@/constants';
import { HEADING_STYLES, LINK_STYLES } from '@constants/colors';
import { ID_COUNTDOWN_SECTION } from '@constants/identifier';

import txtWording from './locales';
import useLang from '@hooks/useLang';
import { MUSLIM_INVITATION, ENABLE_QR_INVITATION } from '@constants/feature-flags';
import useShiftTime from '@hooks/useShiftTime';

function WeddingSection({ ...rest }) {
  const isInvitation = useInvitation();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const lang = useLang();
  const time = useShiftTime();

  return (
    <>
      <Box padding="12px 0 0px 0" bgColor="bgPrimary" {...rest}>
        <WithAnimation>
          <Heading marginBottom="42px" {...HEADING_STYLES} paddingLeft="0px" textAlign="center">
            {isInvitation ? txtWording.title[lang] :"WHEN"}
          </Heading>
        </WithAnimation>
        {/* Wedding info section */}
        <Box padding="0 28px" marginTop="-16px">
          
          {/* Announcement */}
          {!isInvitation && (
            <WithAnimation>
              <WeddingDetail
                date="Saturday, 16 September 2023"
                time={WEDDING_AKAD_TIME}
                title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
                subTitle={false &&
                  <>
                    <span>
                      {WEDDING_AKAD_LOC_NAME}
                    </span>
                    <br />
                    <span>
                      <i>{WEDDING_AKAD_LOC_ROAD}</i>
                    </span>
                  </>
                }
                //location={GOOGLE_MAPS_LINK}
              />
            </WithAnimation>
          )}

          {/* Inv General */}
          {isInvitation && time ==="Shift" && (
            <WithAnimation>
              <WeddingDetail
                date="Saturday, 16 September 2023"
                time={WEDDING_AKAD_TIME}
                title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
                subTitle={
                  <>
                    <span>
                      Dresscode: Formal
                    </span>
                    <br />
                    <span>
                      {WEDDING_AKAD_LOC_NAME}
                    </span>
                    <br />
                    <span>
                      <i>{WEDDING_AKAD_LOC_ROAD}</i>
                    </span>
                  </> 
                }
                //location={GOOGLE_MAPS_LINK}
              />
            </WithAnimation>
          )}
          
          {/* Inv Shift 1 Pemberkatan */}
          {isInvitation && time ==="Pemberkatan" && (
            <WithAnimation>
              <WeddingDetail
                date="Saturday, 16 September 2023"
                time={WEDDING_AKAD_TIME}
                title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
                subTitle={
                  <>
                    <span>
                      Dresscode: Formal
                    </span>
                    <br />
                    <span>
                      {WEDDING_AKAD_LOC_NAME}
                    </span>
                    <br />
                    <span>
                      <i>{WEDDING_AKAD_LOC_ROAD}</i>
                    </span>
                  </> 
                }
                //location={GOOGLE_MAPS_LINK}
              />
            </WithAnimation>
          )}
          
          {/* Inv Shift 2 Pemberkatan & Resepsi */}
          {isInvitation && time ==="Pemberkatan-Resepsi" && (
            <WithAnimation>
              <WeddingDetail
                date="Saturday, 16 September 2023"
                time={WEDDING_AKAD_TIME}
                title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
                subTitle={
                  <>
                    <span>
                      Dresscode: Formal
                    </span>
                    <br />
                    <span>
                      {WEDDING_AKAD_LOC_NAME}
                    </span>
                    <br />
                    <span>
                      <i>{WEDDING_AKAD_LOC_ROAD}</i>
                    </span>
                  </> 
                }
                //location={GOOGLE_MAPS_LINK}
              />
            </WithAnimation>
          )}
          {isInvitation && time ==="Pemberkatan-Resepsi" && (
            <WithAnimation>
              <WeddingDetail
                date="Saturday, 16 September 2023"
                time={WEDDING_RESEPSI_TIME}
                title={txtWording.reception[lang]}
                subTitle={
                  <>
                    <span>
                      Dresscode: Formal
                    </span>
                    <br />
                    <span>
                      {WEDDING_RESEPSI_LOC_NAME}
                    </span>
                    <br />
                    <span>
                      <i>{WEDDING_RESEPSI_LOC_ROAD}</i>
                    </span>
                  </>
                }
                location={GOOGLE_MAPS_LINK}
              />
            </WithAnimation>
          )}
          
          {false && (
            <WithAnimation>
              <Button {...LINK_STYLES} variant="ghost" padding="0" onClick={onOpen}>
                Bali Guest Information
              </Button>
            </WithAnimation>
          )}
        </Box>
        
        {ENABLE_QR_INVITATION && isInvitation && (
          <WithAnimation>
            <Center>
              <QRSection />
            </Center>
          </WithAnimation>
        )}
        
        <WithAnimation>
          <Heading
            {...HEADING_STYLES}
            textAlign="center"
            paddingLeft="0px"
            margin="52px 0 26px 0"
            name={ID_COUNTDOWN_SECTION}
          >
            COUNTDOWN
          </Heading>
        </WithAnimation>

        <Box width="100%" bgColor="bgSecondary" padding="12px 0 32px 0" color="secondaryColorText">
          <WithAnimation>
            <Center>
              <CountDown lang="en" />
            </Center>
          </WithAnimation>
          {isInvitation && (
            <WithAnimation>
              <Center>
                <AddToCalendarButton
                  marginTop="16px"
                  size="sm"
                  text={txtWording.calendar[lang]}
                  variant="ghost"
                  fontSize="md"
                  colorScheme="blackAlpha"
                  color="mainColorTextLight"
                  bgColor="bgAlternative"
                />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </Box>
      {isInvitation && <BaliGuestInformation lang={'en'} onClose={onClose} visible={isOpen} />}
    </>
  );
}

export default React.memo(WeddingSection);
