import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { bool, string } from 'prop-types';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  Text,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Wishlist from './WishlishCard';


import txtWording from './locales';
import { API_HOSTNAME, API_TABLE_NAME } from '@/constants';
import { LOADING_STATE, INPUT_COMMON_PROPS, ALERT, FORM_LABEL_PROPS, ERROR_TYPE } from './types';
import { HEADING_STYLES } from '@constants/colors';
import { ID_WISH_SECTION } from '@constants/identifier';

const enc = (string) => encodeURIComponent(string);

function WishesSection({ lang }) {
  const [loadingType, setLoadingType] = useState(LOADING_STATE.IDLE);
  const [wishlist, setWishlist] = useState([]);
  const [showAlert, setShowAlert] = useState(ALERT);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const [name, setName] = useState('');
  const [ucapan, setUcapan] = useState('');
  const calledOne = useRef(false);


  const handleSetState = (e, setState) => {
    const value = e.target.value;
    setErrorType(ERROR_TYPE);
    setState(value);
  };

  const finishLoading = () => {
    setLoadingType(LOADING_STATE.IDLE);
  };

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      messageTitle = 'Oops!';
      messageContent = txtWording.failedMessage[lang];
    }

    setShowAlert({ ...ALERT, messageTitle, messageContent, error: !isSuccess, success: isSuccess });
  };

  /**
   * function to get wishlist data
   * @return {void}
   */
  const getData = async () => {
    setLoadingType(LOADING_STATE.GET_DATA);

    try {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'GET',
      };

      const rawResult = await fetch(
        `${API_HOSTNAME}?action=read&tableName=${API_TABLE_NAME}`,
        options,
      );
      const response = await rawResult.json();

      if (response.success) {
        setWishlist(response.data || []);
      } else {
        console.error('ERR_WHEN_GET_DATA', 200);
      }
      finishLoading();
    } catch (e) {
      finishLoading();
      console.error('ERR_WHEN_CALL_DATA', 500);
    }

    calledOne.current = true;
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validate input data
    if (!name || !ucapan) {
      setErrorType({
        name: !name && txtWording.requiredField[lang],
        ucapan: !ucapan && txtWording.requiredField[lang],
      });
      return;
    }

    setLoadingType(LOADING_STATE.SUBMIT_DATA);

    try {
      const time = dayjs(new Date()).format('DD MMM YYYY | hh:mm:ss A');
      const config = `tableName=${API_TABLE_NAME}&action=insert_wish`;
      const query = `?${config}&nama=${enc(name)}&wish=${enc(ucapan)}&time=${enc(time)}`;

      const rawResult = await fetch(API_HOSTNAME + query, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });

      const response = await rawResult.json();
      if (response.success) {
        setName('');
        setUcapan('');
        getData();
        handleSetAlert(true);
      } else {
        handleSetAlert(false);
      }
    } catch (e) {
      handleSetAlert(false);
    }

    finishLoading();
  };

  /** Side effect to autoscroll */
  useEffect(() => {
    if (!calledOne.current) {
      getData();
    }
  }, []);

  const renderAlert = () => {
    if (!showAlert.messageTitle) return null;

    return (
      <Box paddingBottom="0" paddingTop="16px" >
        <Alert status={showAlert.success ? 'success' : 'error'} borderRadius="lg">
          <AlertIcon />
          <AlertDescription fontSize="sm">{showAlert.messageContent}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            size="sm"
            onClick={() => setShowAlert(ALERT)}
          />
        </Alert>
      </Box>
    );
  };

  return (
    <Box padding="24px 24px" marginTop='-32px' bgColor="bgPrimary" name={ID_WISH_SECTION}>
      {/* Title & Description sections */}
      <Box textAlign="center" color="mainColorText">
        <WithAnimation>
          <Heading {...HEADING_STYLES} margin="16px 0">
            {txtWording.title[lang]}
          </Heading>
        </WithAnimation>
        <Text>
          {txtWording.desc[lang]}
        </Text>
      </Box>
      {renderAlert()}
      {/* Box for FORM */}
      <WithAnimation>
        <Box paddingTop="2">
          <FormControl margin="8px 0" isInvalid={errorType.name}>
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder="..."
              onChange={(e) => handleSetState(e, setName)}
              value={name}
            />
            <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
          </FormControl>
          <FormControl margin="16px 0" isInvalid={errorType.ucapan}>
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.wish[lang]}:</FormLabel>
            <Textarea
              {...INPUT_COMMON_PROPS}
              placeholder="..."
              onChange={(e) => handleSetState(e, setUcapan)}
              value={ucapan}
            />
            <FormErrorMessage marginTop="4px">{errorType.ucapan}</FormErrorMessage>
          </FormControl>
          <Box>
            <Button
              isFullWidth
              isLoading={loadingType === LOADING_STATE.SUBMIT_DATA}
              color="secondaryColorText"
              fontWeight="light"
              colorScheme="green"
              size="md"
              type="button"
              bgColor="bgSecondary"
              textAlign="left"
              fontFamily="body"
              justifyContent="left"
              borderRadius="0"
              marginTop="12px"
              marginBottom="34px"
              onClick={handleSubmit}
            >
              {txtWording.send[lang]}
            </Button>
          </Box>
        </Box>
      </WithAnimation>
      {/* Wishlist Card */}
      <WithAnimation>
        <Wishlist wishlistData={wishlist} loading={loadingType === LOADING_STATE.GET_DATA} />
      </WithAnimation>
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
