import get from '@/helpers/getLocale';

export default {
  title: get('Doa & Ucapan', `Pray & Wish`),
  desc: get(
    `Silakan kirimkan doa dan ucapan yang tulus untuk kami:`,
    `Please leave your sincere prayers and wishes to us and our family:`,
  ),
  name: get('Nama', 'Name'),
  wish: get('Doa & Ucapan', 'Prayers & Wishes'),
  send: get('Kirim >>', 'Send >>'),
  success: get('Sukses!', 'Success!'),
  successMessage: get(`Terima kasih atas doa dan ucapanya!`, 'Thank you for your prayers and wishes!'),
  failedMessage: get(
    `Terjadi kesalahan. Silahkan refresh browser Anda atau coba lagi nanti.`,
    `Something wrong happened. Please try again or reload the browser.`,
  ),
  requiredField: get('Wajib diisi!', 'This field is required!'),
};
