import React from 'react';
import { Box, Center, Text, Link, Heading, Divider } from '@chakra-ui/react';

import ImgVendor from '@/assets/icons/wangsit.png';
import Image from '@/components/Common/LazyImage';
import useInvitation from '@hooks/useInvitation';

import { THE_BRIDE, SOUND_BY, SOUND_URL, URL_INVITATO } from '@/constants';
import { IMG_AYAT, LOGO_INVITATO } from '@constants/assets';

import txtWording from './locales';
import useLang from '@hooks/useLang';
import { MUSLIM_INVITATION } from '@constants/feature-flags';

function FooterSection() {
  const invitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary">
      <Box textAlign="center" color="mainColorText">
        <Box padding="42px 24px">
          <Text fontFamily='body' fontSize='md'>
            {MUSLIM_INVITATION ? txtWording.ayatMuslim[lang] : txtWording.ayat[lang]}
          </Text>
          <Text>{MUSLIM_INVITATION ? txtWording.ayatNameMuslim[lang] : txtWording.ayatName[lang]}</Text>
        </Box>
        <Image
          src={IMG_AYAT}
          loading="lazy"
          width="100%"
        />
      </Box>
      <Box padding="42px 18px" textAlign="center" minHeight="80vh" bgColor="bgPrimary">
        <Center height="80vh">
          <Box>
            <Heading
              color="mainColorText"
              textAlign="center"
              letterSpacing="2px"
              fontSize='2xl'
              textTransform="uppercase"
            >
              SINCERE LOVE, <br />{THE_BRIDE}
            </Heading>
            <Text color="mainColorText" fontSize="md" padding='12px 12px'>
              {invitation ? txtWording.closingInvitation[lang] : txtWording.closingAnnouncement[lang]}
            </Text>
            <Text color="mainColorText" fontSize="xl">
              #VINallyLIVINtogether
            </Text>
          </Box>
        </Center>
      </Box>
      <Box
        padding="24px 0"
        bgColor="bgSecondary"
        width="100%"
        color="secondaryColorText"
        textAlign="center"
      >
        <Center>
          <Link href={URL_INVITATO} target="_blank">
            <Image src={LOGO_INVITATO} maxWidth="105px" margin="0 0 2px 0" />
          </Link>
        </Center>
        <Text textAlign="center" fontSize="sm">
          Designed by @clarissandriany
        </Text>
        <Link href={URL_INVITATO} target="_blank">
          <Text textAlign="center" fontSize="sm">
            Created with Love by Invitato
          </Text>
        </Link>
        {false && (
          <>
            <Center>
              <Divider
                margin="12px 0 4px"
                maxWidth="80px"
                opacity="1"
                height="1px"
                bgColor="white"
                border="none"
                borderBottom="none"
              />
            </Center>
            <Center>
              <Link href="https://www.instagram.com/wangsiteo/" target="_blank">
                <Image src={ImgVendor} maxWidth="90px" margin="0 0 -4px 0" />
              </Link>
            </Center>
            <Link href="https://www.instagram.com/wangsiteo/" target="_blank">
              <Text textAlign="center" fontSize="sm">
                In Collaboration with Wangsit WO
              </Text>
            </Link>
          </>
        )}
        <Text fontSize="sm" paddingTop="2px">
          {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
        </Text>
        <Link textAlign="center" href={SOUND_URL} fontSize="sm" isExternal>
          {`Song by ${SOUND_BY}`}
        </Link>
      </Box>
    </Box>
  );
}

export default FooterSection;
