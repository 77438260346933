import get from '@/helpers/getLocale';

export default {
  openingGeneralAnnouncement: get("Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:", 
  "By the grace and blessing of God, we joyfully announce the wedding of:"),
  openingGeneralInvitation: get("Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:", 
  'By the grace and blessing of God, we request the honor of your presence at the wedding of:'),
  
  openingMuslimAnnouncement: get("Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:", 
  "By the grace and blessing of Allah SWT, we joyfully announce the wedding of:"),
  openingMuslimInvitation: get("Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:", 
  "By the grace and blessing of Allah SWT,> we request the honor of your presence at the wedding of:"),  
};
