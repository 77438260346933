import React, { useState, useCallback } from 'react';
import { animateScroll } from 'react-scroll';
import { Box } from '@chakra-ui/react';

import CoverSection from '@components/Section/Cover';
import Welcome from '@components/Section/Welcome';
import CoupleInfoSection from '@components/Section/CoupleInfo';
import WeddingSection from '@components/Section/Wedding';
import RSVPSection from '@components/Section/RSVP';
import YoutubeLive from '@components/Section/YoutubeLive';
import GiftCard from '@components/Section/GiftCard';
import WishesSection from '@components/Section/Wishes';
import PhotoGallery from '@components/Section/PhotoGallery';
import Footer from '@components/Section/Footer';
import LoveStory from '@components/Section/LoveStory';
import InstagramFilter from '@components/Section/InstagramFilter';
import CovidProtocol from '@components/Common/CovidModal/Lazy';

import Layout from '@components/Layout';
import FloatingMusic from '@components/Common/FloatingMusic/Lazy';
import Navigation from '@components/Common/Navigation';
import DarkmodeNotification from '@components/Common/DarkmodeNotification';

import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';
import * as ID from '../constants/identifier';
import * as FEATURE from '../constants/feature-flags';

const ELEMENT_CONTENT_ID = 'invitato-detail-content';

export default function Home() {
  const [showDetailContent, setShowDetailContent] = useState(false);
  const invitation = useInvitation();
  const lang = useLang();

  /**
   * function to show detail invitation
   * @returns {void}
   */
  const handleClickDetail = useCallback(() => {
    setShowDetailContent(true);
    const element = document.getElementById(ELEMENT_CONTENT_ID).offsetTop;
    animateScroll.scrollTo(element);
  }, []);

  /**
   * function to render invitation content
   * @returns {JSX.Element}
   */
  const renderContent = () => {
    if (!showDetailContent) return null;

    return (
      <Box id={ELEMENT_CONTENT_ID}>
        <Welcome lang={lang} />
        <LoveStory lang={lang} />
        <CoupleInfoSection name={ID.ID_COUPLE_SECTION} />
        <WeddingSection name={ID.ID_DETAIL_SECTION} />
        {invitation && FEATURE.ENABLE_RSVP && <RSVPSection lang={lang} name={ID.ID_RSVP_SECTION} />}
        {FEATURE.ENABLE_LIVE_STREAMING && <YoutubeLive lang={lang} name={ID.ID_YOUTUBE_SECTION} />}
        {FEATURE.ENABLE_GIFT_CARD && invitation && <GiftCard lang={lang} name={ID.ID_GIFT_SECTION} />}
        <PhotoGallery />
        {FEATURE.ENABLE_INSTAGRAM_FILTER && invitation && <InstagramFilter />}
        <WishesSection lang={lang} />
        <Footer />
      </Box>
    );
  };

  return (
    <Layout>
      {FEATURE.ENABLE_MUSIC_BACKSOUND && <FloatingMusic shouldShowMusicIcon={showDetailContent} />}
      {FEATURE.ENABLE_DARKMODE_NOTIFICATION && <DarkmodeNotification lang={lang} />}
      {showDetailContent && <Navigation />}
      {FEATURE.ENABLE_COVID_PROTOCOL && showDetailContent && invitation && (
        <CovidProtocol visible={showDetailContent} lang={lang} />
      )}
      <CoverSection loaded={showDetailContent} onSeeDetail={handleClickDetail} />
      {renderContent()}
    </Layout>
  );
}
