import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Heading,
} from '@chakra-ui/react';

import txtWording from './locales';
import { string } from 'prop-types';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

function ModalAddress({ onClose, visible, onCopyText, lang }) {
  const name = 'Kelvin & Livinna';
  const address = `Jl. Pondok Kelapa V Blok C8 No. 35, Jakarta Timur 13450`;

  const renderBank = () => {
    return (
      <Box padding="16px 0">
        <Text textAlign="center" color="mainColorText" marginTop="0" fontWeight="bold">
          {name} <br />
        </Text>
        <Text textAlign="center" fontFamily="body" color="black" fontSize="md">
          {address}
        </Text>
        <Center>
          <Button
            size="sm"
            fontWeight="normal"
            color="mainColorText"
            colorScheme="blackAlpha"
            bgColor="bgAlternative"
            fontStyle="italic"
            marginTop="16px"
            onClick={() => onCopyText(`${name} - ${address}`, txtWording.successCopyAddress[lang])}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton marginTop="12px" {...DEFAULT_BUTTON_PROPS} />
        <Heading
          //borderBottomWidth="1px"
          fontSize="3xl"
          marginTop="26px"
          color="mainColorText"
          fontWeight="normal"
          textAlign="center"
          textTransform="uppercase"
        >
          {txtWording.sendGift[lang]}
        </Heading>
        <DrawerBody>
          <Text align="center" fontFamily="body" fontSize="md" margin="16px 0" color="black">
            {txtWording.modalDescAddress[lang]}
          </Text>
          {renderBank()}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalAddress.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalAddress.defaultProps = {
  lang: 'id',
};

export default ModalAddress;
