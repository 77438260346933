import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Heading,
  useToast,
  Text,
  Link,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import useMutation from '@/hooks/useMutation';
import useGuestName from '@/hooks/useGuestName';
import usePartner from '@hooks/usePartner';
import useShiftTime from '@hooks/useShiftTime';

import { API_HOSTNAME, API_RSVP_TABLE } from '@/constants';
import { txtForm } from './locales';
import { HEADING_STYLES } from '@constants/colors';

import { txtTitle } from './locales';
import { ENABLE_GUEST_PERSONALIZATION } from '@constants/feature-flags';
//import useShiftTime from '@hooks/useShiftTime';

const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};


const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgAlternative',
  color: 'blackAlpha.700',
  fontSize: 'md',
  borderRadius: '0',
  fontFamily: 'body',
  _placeholder: { color: 'blackAlpha.700' },
  _focus: { borderColor: 'mainColorText' },
};

const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'bgAlternative',
  color: 'blackAlpha.700',
  fontFamily: 'body',
  fontSize: 'md',
  _focus: { borderColor: 'mainColorText' },
};

const FORM_LABEL_PROPS = {
  fontSize: 'md',
  color: 'mainColorText',
  margin: '8px 0',
  fontFamily: 'body',
  marginTop: '24px',
};

const enc = (s) => {
  return encodeURIComponent(s);
};

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  //const shift = useShiftTime();
  const toast = useToast();
  const time = useShiftTime();
  const guestName = useGuestName();
  const finalName = guestName.toLowerCase() === 'guest' ? '' : guestName;
  const partner = usePartner();

  const [onSentConfirmation] = useMutation(API_HOSTNAME, API_RSVP_TABLE, 'insert');

  const [name, setName] = useState(() => finalName);
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('-');
  const [attended, setAttended] = useState(TYPE.YES);
  const [bringPartner, setBringPartner] = useState(1);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const [loading, setLoading] = useState(false);

  /**
   * function to set state
   * @param {object} e - html event value
   * @param {function} setState
   * @param {string} typeState
   * @returns
   */
  const onSetState = (e, setState, typeState) => {
    const value = e.target.value;
    setState(value);

    if (typeState === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrorType({ ...errorType, email: 'Invalid email address' });
        return;
      }
    }

    if (typeState === 'phone') {
      if (!/\d+/.test(value) || value.length > 13) {
        setErrorType({ ...errorType, phone: 'Invalid phone number' });
        return;
      }
    }

    setErrorType(ERROR_TYPE);
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    if (errorType.name || errorType.phone || errorType.address) return;

    if (!name || !phone || !address) {
      setErrorType({
        name: !name ? txtForm.required[lang] : '',
        phone: !phone ? txtForm.required[lang] : '',
        address: !address ? txtForm.required[lang] : '',
      });

      return;
    }

    setLoading(true);

    const query =
      `nama=${enc(name)}` +
      `&hp=${phone}` +
      `&hadir=${attended}` +
      `&jumlah_tamu=${attended===TYPE.NO ? '' : bringPartner}` +
      `&alamat=${enc(address)}` 
      //+ `&shift=${enc(attended===TYPE.NO ? '' : shift == 'akad' ? 'Pemberkatan' : 'Resepsi')}`
      ;

    const result = await onSentConfirmation(query);

    if (result.success) {
      toast({
        title: txtForm.success[lang],
        description: txtForm.msgSuccess[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      setPhone('');
      setAddress('');
    } else {
      toast({
        title: 'Oops!',
        description: txtForm.msgError[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  return (
    <Box padding="0 0 16px 0" bgColor="bgPrimary" {...rest}>
      <Box bgColor="bgPrimary" >
        <Box padding="8px">
          <WithAnimation>
            <Box padding="24px" width="100%">
              {/* Title & Desc Section */}
              <Box textAlign="center" color="mainColorText">
                <Heading {...HEADING_STYLES} paddingLeft='0px' textAlign='center' marginTop="16px">
                  RSVP
                </Heading>
                <Text fontSize="md" margin="16px 0 24px 0">
                  {txtTitle[lang]}
                </Text>
                {time ==="Shift" && (
                  <Link href="https://forms.gle/wMeKG1stgL7PFNLz6" target="_blank">
                    <Button
                      size="sm"
                      fontSize='md'
                      fontStyle='italic'
                      fontWeight="light"
                      colorScheme="blackAlpha"
                      bgColor="bgAlternative"
                      color="blackAlpha.800"
                      marginTop='2px'
                    >
                      Submit RSVP
                    </Button>
                  </Link>
                )}
                {time ==="Pemberkatan" && (
                  <Link href="https://forms.gle/wMeKG1stgL7PFNLz6" target="_blank">
                    <Button
                      size="sm"
                      fontSize='md'
                      fontStyle='italic'
                      fontWeight="light"
                      colorScheme="blackAlpha"
                      bgColor="bgAlternative"
                      color="blackAlpha.800"
                      marginTop='2px'
                    >
                      Submit RSVP
                    </Button>
                  </Link>
                )}
                {time ==="Pemberkatan-Resepsi" && (
                  <Link href="https://forms.gle/Q38aL4rv7iEjWMA87" target="_blank">
                    <Button
                      size="sm"
                      fontSize='md'
                      fontStyle='italic'
                      fontWeight="light"
                      colorScheme="blackAlpha"
                      bgColor="bgAlternative"
                      color="blackAlpha.800"
                      marginTop='2px'
                    >
                      Submit RSVP
                    </Button>
                  </Link>
                )}
              </Box>
              {/* Form Sections - Name */}
              {false && (
                <>
                  <FormControl margin="12px 0 8px 0" isInvalid={errorType.name}>
                    <FormLabel {...FORM_LABEL_PROPS}>{txtForm.name[lang]}:</FormLabel>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      placeholder="..."
                      value={name}
                      onChange={(e) => onSetState(e, setName)}
                    />
                    <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
                  </FormControl>
                  
                  {/* Form Sections - Phone Number */}
                  <FormControl margin="16px 0" isInvalid={errorType.phone}>
                    <FormLabel {...FORM_LABEL_PROPS}>{txtForm.hp[lang]}:</FormLabel>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      placeholder="..."
                      value={phone}
                      onChange={(e) => onSetState(e, setPhone, 'phone')}
                    />
                    <FormErrorMessage marginTop="4px">{errorType.phone}</FormErrorMessage>
                  </FormControl>
                  
                  {/* Form Sections - Address */}
                  {false && (
                    <FormControl margin="8px 0" isInvalid={errorType.address}>
                      <FormLabel {...FORM_LABEL_PROPS}>{txtForm.address[lang]}:</FormLabel>
                      <Input
                        {...INPUT_COMMON_PROPS}
                        height="40px"
                        placeholder="..."
                        value={address}
                        onChange={(e) => onSetState(e, setAddress)}
                      />
                      <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
                    </FormControl>
                  )}
                  
                  {/* Form Sections - Attendance */}
                  <FormControl margin="8px 0">
                    <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYoutAttend[lang]}</FormLabel>
                    <Select
                      {...SELECT_COMMON_PROPS}
                      value={attended}
                      onChange={(e) => onSetState(e, setAttended)}
                    >
                      <option value={TYPE.YES} style={{color: 'black'}}>
                        {txtForm.willAttend[lang]}
                      </option>
                      <option value={TYPE.NO} style={{color: 'black'}}>{txtForm.noAttend[lang]}</option>
                    </Select>
                  </FormControl>           
                  {/* Form Sections - Partner */}
                  {attended === TYPE.YES && (
                    <>
                      <FormControl>
                        <FormLabel {...FORM_LABEL_PROPS}>
                          {txtForm.willYouBringPartner[lang]}
                        </FormLabel>
                        <Select
                          {...SELECT_COMMON_PROPS}
                          value={bringPartner}
                          onChange={(e) => onSetState(e, setBringPartner)}
                        >
                        {ENABLE_GUEST_PERSONALIZATION
                          ?
                            <>
                              {!partner && (
                                <option value={1} style={{color: 'black'}}>1</option>
                              )}
                              {partner === 1 && (
                                <option value={1} style={{color: 'black'}}>1</option>
                              )}
                              {partner === 2 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                </>
                              )}
                              {partner === 3 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                  <option value={3} style={{color: 'black'}}>3</option>
                                </>
                              )}
                              {partner === 4 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                  <option value={3} style={{color: 'black'}}>3</option>
                                  <option value={4} style={{color: 'black'}}>4</option>
                                </>
                              )}
                              {partner === 5 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                  <option value={3} style={{color: 'black'}}>3</option>
                                  <option value={4} style={{color: 'black'}}>4</option>
                                  <option value={5} style={{color: 'black'}}>5</option>
                                </>
                              )}
                              {partner === 6 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                  <option value={3} style={{color: 'black'}}>3</option>
                                  <option value={4} style={{color: 'black'}}>4</option>
                                  <option value={5} style={{color: 'black'}}>5</option>
                                  <option value={6} style={{color: 'black'}}>6</option>
                                </>
                              )}
                              {partner > 6 && (
                                <>
                                  <option value={1} style={{color: 'black'}}>1</option>
                                  <option value={2} style={{color: 'black'}}>2</option>
                                  <option value={3} style={{color: 'black'}}>3</option>
                                  <option value={4} style={{color: 'black'}}>4</option>
                                  <option value={5} style={{color: 'black'}}>5</option>
                                  <option value={6} style={{color: 'black'}}>6</option>
                                  <option value={7} style={{color: 'black'}}>7</option>
                                </>
                              )}
                            </>
                          :
                            <>
                              <option value={1} style={{color: 'black'}}>1</option>
                              <option value={2} style={{color: 'black'}}>2</option>
                              <option value={3} style={{color: 'black'}}>3</option>
                              <option value={4} style={{color: 'black'}}>4</option>
                              <option value={5} style={{color: 'black'}}>5</option>
                              <option value={6} style={{color: 'black'}}>6</option>
                              <option value={7} style={{color: 'black'}}>7</option>
                            </>
                        }
                          </Select>
                          {bringPartner === TYPE.YES && false && (
                            <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                              *) Berdasarkan kondisi saat ini, satu tamu hanya boleh membawa 1 orang partner
                              atau pasangan dalam satu undangan. Terima kasih atas pengertiannya.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                )}            
                  <Center>
                    <Button
                      isFullWidth
                      color="secondaryColorText"
                      fontWeight="light"
                      colorScheme="green"
                      isLoading={loading}
                      marginTop="24px"
                      size="md"
                      type="button"
                      bgColor="bgSecondary"
                      onClick={onSubmitForm}
                      textAlign="left"
                      fontFamily="body"
                      justifyContent="left"
                      borderRadius="0"
                    >
                      {txtForm.submit[lang]}
                    </Button>
                  </Center>
                </>
              )}
            </Box>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
