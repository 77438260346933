const img = '';

export default [
  {
    img: img,
    title: 'NOVEMBER 2021',
    description: `We Met.`,
  },
  {
    img: img,
    title: 'JANUARY 2022',
    description: `After 2 months (and a period of ghosting), we started chatting and went on our first date @Oku Izakaya Kempinski`,
  },
  {
    img: img,
    title: 'APRIL 2022',
    description: `We started dating!`,
  },
  {
    img: img,
    title: 'OCTOBER 2022',
    description: `First trip together to Bali – “To know someone in and out, one’s gotta travel together” was a motto Livinna always had. The trip was a first of many where we knew each other better, each a chance to love one another more deeply.`,
  },
  {
    img: img,
    title: 'FEBRUARY 2023',
    description: `Proposal @Phoenix Bar – What started as a birthday surprise turned to be one of our most unforgettable nights – serenaded by Christian Bautista’s performance we enjoyed each other’s presence and Kelvin got down on one knee to a lifetime of joy and adventure together.`,
  },
  {
    img: img,
    title: 'APRIL 2023',
    description: `Prewed Photoshoot @ Japan – A trip impossible without God’s intervention. So many doors were opened and they led us to an unforgettable moment in Japan.`,
  },
  {
    img: img,
    title: '5 AUGUST 2023',
    description: `Sangjit Day.`,
  },
  {
    img: img,
    title: '16 SEPTEMBER 2023',
    description: `Wedding Day. And now we invite you to share and celebrate the Next chapter of our story.`,
  },
];
